import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import styled from "styled-components/macro";

import GlobalStyle from "../components/GlobalStyle";

import Loader from "../components/Loader";
import Sidebar from "../components/sidebar/Sidebar";
import dashboardItems from "../components/sidebar/dashboardItems";
import Footer from "../components/Footer";
import Settings from "../components/Settings";
import Navbar from "../components/navbar/Navbar";

import { CssBaseline, Drawer, Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { usePDF, Resolution, Margin } from "react-to-pdf";

import Divider from "@mui/material/Divider";
import { useLocation } from "react-router-dom";

const drawerWidth = 270;

const AppContent = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(5),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    width: `calc(100vw - (${drawerWidth}px + 12px))`,
  }),
}));

const MainContent = styled(Box)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }
`;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const options = {
  // default is `save`
  method: "open",
  // default is Resolution.MEDIUM = 3, which should be enough, higher values
  // increases the image quality but also the size of the PDF, so be careful
  // using values higher than 10 when having multiple pages generated, it
  // might cause the page to crash or hang.
  resolution: Resolution.HIGH,
  page: {
    // margin is in MM, default is Margin.NONE = 0
    margin: Margin.SMALL,
    // default is 'A4'
    format: "letter",
    // default is 'portrait'
    orientation: "landscape",
  },
  canvas: {
    // default is 'image/jpeg' for better size performance
    mimeType: "image/png",
    qualityRatio: 1,
  },
  // Customize any value passed to the jsPDF instance and html2canvas
  // function. You probably will not need this and things can break,
  // so use with caution.
  overrides: {
    // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
    pdf: {
      compress: true,
    },
    // see https://html2canvas.hertzen.com/configuration for more options
    canvas: {
      useCORS: true,
    },
  },
};

const Dashboard = ({ children }) => {
  const loader = useSelector((state) => state.app.loader);
  const printing = useSelector((state) => state.app.printing);
  const location = useLocation();
  const selected = useSelector((state) => state.app.selectedListings);

  const { toPDF, targetRef } = usePDF({
    filename: `ATX_OBP_${
      new Date()
        .toISOString()
        .replaceAll("_", ".")
        .replace("T", "_")
        .split(".")[0]
    }.pdf`,
    options: options,
  });

  const initialState = () => {
    const expandNav = localStorage.getItem("expandNav");

    return expandNav ? JSON.parse(expandNav) : false;
  };

  const [open, _setOpen] = useState(initialState());
  const setOpen = (open) => {
    localStorage.setItem("expandNav", JSON.stringify(open));
    _setOpen(open);
  };

  React.useEffect(() => {
    if (selected && selected.length > 0 && !open) {
      setOpen(true);
    }
  }, [selected, open]);

  React.useEffect(() => {
    setOpen(!printing);
    // eslint-disable-next-line
  }, [printing]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const theme = useTheme();
  const isLgDown = useMediaQuery(theme.breakpoints.down("lg"));

  const getHeading = () => {
    switch (location.pathname) {
      case "/":
        return "Dashboard";
      case "/listings":
        return "Listings";
      case "/complaints":
        return "Complaints";
      case "/sellers":
        return "Sellers";
      case "/domains":
        return "Domains";
      case "/investigations":
        return "Investigations";
      case "/ads":
        return "Advertisements";
      default:
        return "";
    }
  };

  if (!loader) {
    return (
      <Box sx={{ display: "flex" }} ref={targetRef}>
        <CssBaseline />
        <GlobalStyle />
        <Navbar
          open={open}
          handleDrawerOpen={handleDrawerOpen}
          handleDrawerClose={handleDrawerClose}
          context={getHeading()}
          toPDF={toPDF}
        />
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              boxShadow: "4px 0px 2px 3px rgb(0 0 0 / 10%)",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <Divider />
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            items={dashboardItems}
          />
        </Drawer>
        <AppContent open={open}>
          <DrawerHeader />
          <MainContent
            sx={
              isLgDown
                ? { paddingTop: "20px" }
                : { paddingTop: "15px", width: "100%" }
            }
          >
            {children}
            <Outlet />
          </MainContent>
          <Footer />
        </AppContent>
        <Settings />
      </Box>
    );
  } else {
    return <Loader />;
  }
};

export default Dashboard;
